<template>
  <div
    class="section about has-text-centered"
    v-if="
      validCommittee &&
        validCommittee.isValidCommittee &&
        validCommittee.isValidCommittee.committeeID > 0
    "
  >
    <div class="container">
      <div class="flex flex-wrap -mx-2">
        <div class="w-full lg:w-1/3 p-2">
          <div class="rounded shadow bg-white p-10 h-127">
            <div
              class="rounded-full w-16 h-16 flex items-center justify-center border-4 border-gray-200 bg-blue-200 mx-auto m-0 mb-4"
            >
              <i :class="`icon-communications`" />
            </div>

            <h1 class="text-base text-xl m-0 mb-6 text-blue-800 font-semibold">
              Architectural Requests Awaiting Votes
            </h1>
            <div>
              <button class="button is-large is-info">
                <h2 style="color: white; padding:5px;" v-if="architecturalSubmissionsList">
                  {{ architecturalSubmissionsList.length }}
                </h2>
                <h2 style="color: white; padding:5px;" v-else>0</h2>
              </button>
            </div>
            <div class="pt-10">
              <b-button
                tag="router-link"
                size="is-medium"
                :to="{
                  name: 'architecturalRequests',
                  params: {
                    filter: 'pending'
                  }
                }"
                type="is-primary"
                >Vote Now</b-button
              >
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/3 p-2">
          <div class="rounded shadow bg-white p-10 h-127">
            <div
              class="rounded-full w-16 h-16 flex items-center justify-center border-4 border-gray-200 bg-blue-200 mx-auto m-0 mb-4"
            >
              <i :class="`icon-communications`" />
            </div>

            <h1 class="text-base text-xl m-0 mb-6 text-blue-800 font-semibold">
              Current Work Orders
            </h1>
            <div class="text-center">
              <button class="button is-large is-info">
                <h2 style="color: white; padding:5px;" v-if="workOrderList">
                  {{ workOrderList.length }}
                </h2>
                <h2 style="color: white; padding:5px;" v-else>0</h2>
              </button>
            </div>
            <div class="pt-10">
              <b-button
                tag="router-link"
                size="is-medium"
                :to="{
                  name: 'workOrders'
                }"
                type="is-primary"
                >View Work Orders</b-button
              >
            </div>
          </div>
        </div>
        <div class="w-full lg:w-1/3 p-2">
          <div class="rounded shadow bg-white p-10 h-127">
            <div
              class="rounded-full w-16 h-16 flex items-center justify-center border-4 border-gray-200 bg-blue-200 mx-auto m-0 mb-4"
            >
              <i :class="`icon-communications`" />
            </div>

            <h1 class="text-base text-xl m-0 mb-6 text-blue-800 font-semibold">
              Current Violations
            </h1>
            <div class="text-center">
              <button class="button is-large is-info">
                <h2 style="color: white; padding:5px;" v-if="violationsList">
                  {{ violationsList.length }}
                </h2>
                <h2 style="color: white; padding:5px;" v-else>0</h2>
              </button>
            </div>
            <div class="pt-10">
              <b-button
                tag="router-link"
                size="is-medium"
                :to="{
                  name: 'violations'
                }"
                type="is-primary"
                >View Violations</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ToastProgrammatic as Toast } from 'buefy'

function notifyError(e) {
  Toast.open({
    duration: 7000,
    message: e.message || e,
    position: 'is-bottom',
    type: 'is-danger'
  })
}

export default {
  name: 'Dashboard',

  components: {},

  data: function() {
    return {
      loading: true,
      isDebug: false
    }
  },

  computed: {},

  mounted() {
    this.loading = true

    this.$store.dispatch('architectural/loadCommitteeSetting')

    this.$store.dispatch('violations/getViolationOccurrences')

    this.$store.dispatch('workorder/getWorkOrderList')

    this.reload()

    this.loading = false
  },

  methods: {
    async reload() {
      this.loading = true

      try {
        if (this.isDebug == true)
          console.debug('Dashobaord committeeSetting=' + JSON.stringify(this.committeeSetting))

        if (this.committeeSetting) {
          this.$store
            .dispatch('architectural/loadValidCommitteeCheck', {
              committeeID: 1
            })
            .then(() => {
              if (this.validCommittee) {
                if (this.isDebug == true)
                  console.debug('validCommittee=' + JSON.stringify(this.validCommittee))

                if (this.validCommittee.isValidCommittee) {
                  this.$store.dispatch('architectural/getArchitecturalSubmissions', {
                    pendingStatus: true
                  })
                }
              }
            })
        }
      } catch (e) {
        notifyError(e)
      }
      this.loading = false
    }
  }
}
</script>

<style></style>
