var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.validCommittee &&
      _vm.validCommittee.isValidCommittee &&
      _vm.validCommittee.isValidCommittee.committeeID > 0
  )?_c('div',{staticClass:"section about has-text-centered"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap -mx-2"},[_c('div',{staticClass:"w-full lg:w-1/3 p-2"},[_c('div',{staticClass:"rounded shadow bg-white p-10 h-127"},[_c('div',{staticClass:"rounded-full w-16 h-16 flex items-center justify-center border-4 border-gray-200 bg-blue-200 mx-auto m-0 mb-4"},[_c('i',{class:"icon-communications"})]),_c('h1',{staticClass:"text-base text-xl m-0 mb-6 text-blue-800 font-semibold"},[_vm._v(" Architectural Requests Awaiting Votes ")]),_c('div',[_c('button',{staticClass:"button is-large is-info"},[(_vm.architecturalSubmissionsList)?_c('h2',{staticStyle:{"color":"white","padding":"5px"}},[_vm._v(" "+_vm._s(_vm.architecturalSubmissionsList.length)+" ")]):_c('h2',{staticStyle:{"color":"white","padding":"5px"}},[_vm._v("0")])])]),_c('div',{staticClass:"pt-10"},[_c('b-button',{attrs:{"tag":"router-link","size":"is-medium","to":{
                name: 'architecturalRequests',
                params: {
                  filter: 'pending'
                }
              },"type":"is-primary"}},[_vm._v("Vote Now")])],1)])]),_c('div',{staticClass:"w-full lg:w-1/3 p-2"},[_c('div',{staticClass:"rounded shadow bg-white p-10 h-127"},[_c('div',{staticClass:"rounded-full w-16 h-16 flex items-center justify-center border-4 border-gray-200 bg-blue-200 mx-auto m-0 mb-4"},[_c('i',{class:"icon-communications"})]),_c('h1',{staticClass:"text-base text-xl m-0 mb-6 text-blue-800 font-semibold"},[_vm._v(" Current Work Orders ")]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"button is-large is-info"},[(_vm.workOrderList)?_c('h2',{staticStyle:{"color":"white","padding":"5px"}},[_vm._v(" "+_vm._s(_vm.workOrderList.length)+" ")]):_c('h2',{staticStyle:{"color":"white","padding":"5px"}},[_vm._v("0")])])]),_c('div',{staticClass:"pt-10"},[_c('b-button',{attrs:{"tag":"router-link","size":"is-medium","to":{
                name: 'workOrders'
              },"type":"is-primary"}},[_vm._v("View Work Orders")])],1)])]),_c('div',{staticClass:"w-full lg:w-1/3 p-2"},[_c('div',{staticClass:"rounded shadow bg-white p-10 h-127"},[_c('div',{staticClass:"rounded-full w-16 h-16 flex items-center justify-center border-4 border-gray-200 bg-blue-200 mx-auto m-0 mb-4"},[_c('i',{class:"icon-communications"})]),_c('h1',{staticClass:"text-base text-xl m-0 mb-6 text-blue-800 font-semibold"},[_vm._v(" Current Violations ")]),_c('div',{staticClass:"text-center"},[_c('button',{staticClass:"button is-large is-info"},[(_vm.violationsList)?_c('h2',{staticStyle:{"color":"white","padding":"5px"}},[_vm._v(" "+_vm._s(_vm.violationsList.length)+" ")]):_c('h2',{staticStyle:{"color":"white","padding":"5px"}},[_vm._v("0")])])]),_c('div',{staticClass:"pt-10"},[_c('b-button',{attrs:{"tag":"router-link","size":"is-medium","to":{
                name: 'violations'
              },"type":"is-primary"}},[_vm._v("View Violations")])],1)])])])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }